/* App.css */
.jumbotron {
  background-blend-mode: darken;
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.myimage{
  background-image: url('./law.JPG');
  background-position: center;
   height: 190px;
   display: flex;
    flex-direction: column; 
    
    align-items: left;
}


.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}